<template>
  <div class="home">
    <slideshow-component />
    <plan-component-vue />
    <about-component />
    <stats-component-vue id="stats" />
    <service-component-vue />
    <event-component-vue />
    <contact-component-vue />
  </div>
</template>

<script>
// @ is an alias to /src
import slideshowComponent from "@/components/home/slideshowComponent.vue";
import planComponentVue from "../components/home/planComponent.vue";
import aboutComponent from "../components/home/aboutComponent.vue";
import statsComponentVue from "@/components/home/statsComponent.vue";
import serviceComponentVue from "../components/home/serviceComponent.vue";
import eventComponentVue from "../components/home/eventComponent.vue";
import contactComponentVue from "../components/home/contactComponent.vue";
export default {
  name: "HomeView",
  components: {
    slideshowComponent,
    planComponentVue,
    aboutComponent,
    statsComponentVue,
    serviceComponentVue,
    eventComponentVue,
    contactComponentVue,
  },
};
</script>
