<template>
  <div class="about">
    <div class="image">
      <img src="@/assets/abtchair.png" alt="about" />
    </div>
    <div class="text">
      <div class="title">
        <div class="line"></div>
        <div class="headline">
          <div class="main">about us</div>
          <div class="sub">old space new stories</div>
        </div>
      </div>
      <div class="content">
        Gore Mangal Karyalay is a multipurpose hall. Which does not just let you
        celebrate all your functions but also "maintains the decorum and
        sanctity of every occasion". It makes your moments memorable,
        hassle-free and a more shared experience. <br /><br />
        An experience that you will relish, revere and recommend to everyone.
      </div>
      <div class="rmore">
        <div class="text">READ FULL STORY</div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.about {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5%;
}

.image {
  width: 50%;
}

.image img {
  width: 100%;
}

.text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 5%;
}

.text .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5%;
}

.text .title .line {
  width: 5rem;
  height: 2px;
  background-color: #bebebe;
  margin-right: 2rem;
}

.text .title .headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text .title .headline .main {
  font-size: 3rem;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
}

.text .title .headline .sub {
  font-size: 1rem;
  font-weight: 600;
  color: #bebebe;
  text-transform: uppercase;
}

.text .content {
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
  line-height: 2.5rem;
}

.text .rmore {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
}

.text .rmore .text {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  width: fit-content;
  padding: 0;
  color: #f71010;
}
.text .rmore .line {
  width: 5rem;
  height: 2px;
  background-color: #bebebe;
  margin-left: 2rem;
}
</style>
