<template>
  <div class="service">
    <div class="gridservices">
      <div class="service1" @click="$router.push('/services#marraige')">
        Marraige
      </div>
      <div class="service2" @click="$router.push('/services#munj')">Munj</div>
      <div class="service3" @click="$router.push('/services#reception')">
        Reception
      </div>
      <div class="service4" @click="$router.push('/services#baby-shower')">
        Baby Shower
      </div>
    </div>
    <div class="addservice">
      <div class="title">
        <div class="line"></div>
        <div class="text">
          <div class="head">Our Additional services</div>
          <div class="sub">
            For all 3 generations of Gore' s, Gore Mangal Karyalay is not just a
            place of business. But a channel to reroute their drive for
            philanthropy and spirituality.
          </div>
        </div>
      </div>
      <div class="list">
        <div class="ser">
          <img src="@/assets/ser/parking.png" alt="Parking Space" />
          <div class="sername">Parking Space</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/elevator.png" alt="Lift with Backup" />
          <div class="sername">Lift with Backup</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/guard.png" alt="Security Guards" />
          <div class="sername">Security Guards</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/578659-200.png" alt="Delicious Food" />
          <div class="sername">Delicious Food</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/wedding-arch.png" alt="Decorations" />
          <div class="sername">Decorations</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/rent.png" alt="23 Hrs Hall Rent" />
          <div class="sername">23 Hrs Hall Rent</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/sound-system.png" alt="Sound Systems" />
          <div class="sername">Sound Systems</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/sanitary.png" alt="14 Hygenic Toilets" />
          <div class="sername">14 Hygenic Toilets</div>
        </div>
        <div class="ser">
          <img
            src="@/assets/ser/electric-generator.png"
            alt="Generator Support"
          />
          <div class="sername">Generator Support</div>
        </div>
        <div class="ser">
          <img src="@/assets/ser/camera.png" alt="Photography Support" />
          <div class="sername">Photography Support</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.service {
  padding: 0 5%;
}

/* grid of 3 coloumns and 2rows */
.service .gridservices {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
  height: 720px;
}

/* service 1 */
.service .gridservices .service1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  background: url("@/assets/sermar.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* service 2 */
.service .gridservices .service2 {
  grid-column: 3 / span 2;
  grid-row: 1/2;
  background: url("@/assets/sermun.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

/* service 3 */
.service .gridservices .service3 {
  grid-column: 3/3;
  grid-row: 2/2;
  background: url("@/assets/serrec.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

/* service 4 */
.service .gridservices .service4 {
  grid-column: 4/4;
  grid-row: 2/2;
  background: url("@/assets/serbab.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

/* additional services */
.service .addservice {
  padding: 5% 0;
}

/* title */
.service .addservice .title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2%;
}

/* line */
.service .addservice .title .line {
  width: 120px;
  height: 3px;
  background: #bebebe;
  margin-right: 2%;
  margin-top: 1.5rem;
}

/* head */

.service .addservice .title .text .head {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* sub */
.service .addservice .title .text .sub {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  width: 70%;
}

/* list */
.service .addservice .list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  margin-top: 5%;
}

/* service */
.service .addservice .list .ser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* service name */
.service .addservice .list .ser .sername {
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 5%;
}

/* service image */
.service .addservice .list .ser img {
  height: 4rem;
}

@media (max-width: 768px) {
  .service .gridservices {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: 720px;
  }

  .service .gridservices .service1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }

  .service .gridservices .service2 {
    grid-column: 1 / span 2;
    grid-row: 3 / span 2;
  }

  .service .gridservices .service3 {
    grid-column: 1 / span 2;
    grid-row: 5 / span 2;
  }

  .service .gridservices .service4 {
    grid-column: 1 / span 2;
    grid-row: 7 / span 2;
  }

  .service .addservice .list {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
