<template>
  <div class="contactmain">
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15276.032582036823!2d74.6544935!3d16.825952!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc123b85f0ec01b%3A0xb4fd5731f33ac903!2sGore%20Mangal%20Karyalaya!5e0!3m2!1sen!2sin!4v1685074441663!5m2!1sen!2sin"
        width="450"
        height="600"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="contact">
      <div class="title">
        <div class="line"></div>
        <div class="text">
          <div class="head">Enquire Now</div>
          <div class="sub">We will help you to make your event grand!</div>
        </div>
      </div>
      <div class="form">
        <input type="text" name="name" id="name" placeholder="Your Name" />
        <input
          type="text"
          name="mobile"
          id="mobile"
          placeholder="Your Mobile Number"
        />
        <select name="service" id="service" aria-placeholder="select service">
          <option value="select service">Select Service</option>
          <option value="wedding">Wedding</option>
          <option value="birthday">Birthday</option>
          <option value="corporate">Corporate</option>
          <option value="other">Other</option>
        </select>
        <input type="text" name="city" id="city" placeholder="Your City" />

        <button>Request a call back</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.contactmain {
  width: 100%;
  padding: 5% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
}

.contactmain .map {
  width: 50%;
  height: 600px;
  padding: 0 2rem;
}

.contactmain .map iframe {
  width: 100%;
  height: 100%;
}

.contactmain .contact {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contactmain .contact .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactmain .contact .title .line {
  margin-top: 1.5rem;
  margin-right: 1rem;
  width: 4rem;
  height: 3px;
  background: #bebebe;
}

.contactmain .contact .title .text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
}

.contactmain .contact .title .text .head {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contactmain .contact .title .text .sub {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}

.contactmain .contact .form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5% 0%;
}

.contactmain .contact .form input,
.contactmain .contact .form select {
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-bottom: 1px solid #bebebe;
  outline: none;
  font-size: 1.2rem;
  font-weight: 700;
  color: #bebebe;
}

.contactmain .contact .form input::placeholder,
.contactmain .contact .form select::placeholder {
  color: #bebebe;
}

/* changing color of 1st option to bebebe */
.contactmain .contact .form select option:first-child {
  color: #bebebe;
}

/* changing default option color to bebebe */
.contactmain .contact .form select option {
  color: #000;
}

.contactmain .contact .form select:not(:checked) {
  color: #bebebe;
}

.contactmain .contact .form button {
  width: 50%;
  height: 3rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 0 1rem;
  border: 1px solid #bb0b12;
  border-radius: 5px;
  outline: none;
  background: #bb0b12;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}

.contactmain .contact .form button:hover {
  background: #fff;
  color: #bb0b12;
}
</style>
