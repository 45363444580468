<template>
  <div class="header">
    <div class="info">
      <div class="left">
        <img src="../assets/founder.jpg" alt="founder" />
        <div class="text">
          <div class="grey">Founder of Gore Mangal Karyalaya</div>
          <div class="black">Vedmurti Chintamanrao Gore</div>
        </div>
      </div>
      <div class="center">
        <img src="../assets/logo.jpg" alt="logo" />
      </div>
      <div class="right">
        <div class="sprt">
          <div class="grey">Support</div>
          <div class="red">+91 83800 90021</div>
        </div>
        <div class="horizentalline"></div>
        <div class="rtbtn">
          <router-link to="#inquire"> Book A Function </router-link>
        </div>
      </div>
    </div>
    <nav :class="scrolled">
      <div class="left">
        <router-link to="/">Home</router-link>
        <router-link to="/history">OUR HISTORY</router-link>
        <router-link to="/services">OUR SERVICES</router-link>
      </div>
      <div class="right">
        <router-link to="/calender">Book Marraige</router-link>
        <router-link to="/gallery">OUR GALLERY</router-link>
        <router-link to="/menu">MENU</router-link>
        <router-link to="/contact">CONTACT US</router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      scrolled: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // after scroll of 10rem, add class "sticky" to nav
      if (window.pageYOffset > 10) {
        this.scrolled = "sticky";
      } else {
        this.scrolled = "";
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: #fff;
  border-top: 0.5rem solid #bebebe;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  border-bottom: 1px solid #b3b3b3;
  height: 8rem;
}

.left {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.left img {
  width: 80px;
  height: 100px;
  margin-right: 10px;
}

.left .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.left .text .grey {
  font-size: 1.2rem;
  color: #b3b3b3;
}

.left .text .black {
  font-size: 1.2rem;
  color: #000;
}

.center {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2rem solid #fff;
  border-top: none;
  padding-top: 1.2rem;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}

.center img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.right {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right .sprt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 20px;
}

.right .sprt .grey {
  font-size: 1.2rem;
  font-weight: 700;
  color: #b3b3b3;
}

.right .sprt .red {
  font-size: 1.5rem;
  font-weight: 700;
  color: #f71010;
}

.right .horizentalline {
  width: 3rem;
  height: 1px;
  background-color: #b3b3b3;
  margin-right: 20px;
}

.right .rtbtn {
  /* margin-right: 20px; */
}

.right .rtbtn a {
  padding: 0.5rem 2rem;
  border: 1px solid #b3b3b3;
  border-radius: 1rem;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.right .rtbtn a:hover {
  background-color: #b3b3b3;
  color: #fff;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  height: 3rem;
  background-color: #bb0b12;
  border-bottom: 1px solid #ffd408;
}

/* stick nav after scroll */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  animation: slideDown 0.5s ease-in-out;
  z-index: 100;
  justify-content: flex-end;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

nav .left {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav.sticky .left {
  justify-content: flex-end;
}

nav .left a {
  margin-right: 20px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  /* all capital */
  text-transform: uppercase;
}

nav.sticky .left a {
  margin-right: 0;
  padding: 0.5rem 1rem;
}

nav .left a:hover {
  color: #b3b3b3;
}

nav .right {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav.sticky .right {
  justify-content: flex-start;
}

nav .right a {
  margin-left: 10px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

nav.sticky .right a {
  margin-left: 0;
  padding: 0.5rem 1rem;
}

nav .right a:hover {
  color: #b3b3b3;
}
</style>
