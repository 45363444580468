<template>
  <swiper
    :spaceBetween="30"
    :centeredSlides="true"
    :autoplay="{ delay: 2500, disableOnInteraction: false }"
    :pagination="{ clickable: true }"
    :navigation="true"
    :modules="modules"
    class="homeslideswiper"
  >
    <swiper-slide><img src="@/assets/slide1.jpg" alt="slide" /></swiper-slide>
    <!-- <swiper-slide
      ><img src="@/assets/mainslide.jpg" alt="slide"
    /></swiper-slide> -->
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./style.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style>
.homeslideswiper .swiper {
  width: 100%;
  height: 100%;
}

.homeslideswiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeslideswiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeslideswiper .swiper-button-prev,
.homeslideswiper .swiper-button-next {
  color: beige;
  border: 1px solid beige;
  padding: 2rem;
  margin: 0 3rem;
}
</style>
