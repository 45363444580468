<template>
  <div class="main">
    <div class="image">
      <img src="@/assets/planmehandi.png" alt="mehandi" />
    </div>
    <div class="info">
      <div class="title">
        <div class="line"></div>
        <div class="text">You can plan your marriage now!</div>
      </div>
      <div class="slide">
        <swiper
          :slidesPerView="1"
          :spaceBetween="10"
          :navigation="true"
          :pagination="{
            clickable: true,
          }"
          :breakpoints="{
            '640': {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            '768': {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            '1024': {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }"
          :modules="modules"
          class="planswiper"
        >
          <swiper-slide v-for="event in events" :key="event.time">
            <div class="scontent">
              <img src="@/assets/tabla.png" alt="tabla" />
              <hr />
              <p>{{ formatDate(event.start) }}</p>
            </div>
          </swiper-slide>
          <!-- <swiper-slide>
            <div class="scontent">
              <img src="@/assets/tabla.png" alt="tabla" />
              <hr />
              <p>28th April</p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="scontent">
              <img src="@/assets/tabla.png" alt="tabla" />
              <hr />
              <p>28th April</p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="scontent">
              <img src="@/assets/tabla.png" alt="tabla" />
              <hr />
              <p>28th April</p>
            </div>
          </swiper-slide> -->
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      events: [],
    };
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  async created() {
    await this.axios
      .get("http://13.233.174.75:3000/api/calender")
      .then((res) => {
        let data = res.data;
        data.forEach((element) => {
          let obj = {
            title: element.title,
            start: element.start,
          };

          // Check if the event is already in the array
          if (!this.events.some((event) => event.start === obj.start)) {
            this.events.push(obj);
          }
        });
      });
  },
  methods: {
    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5% 5%;
  background-image: url("@/assets/planbg.jpg");
}

.image {
  width: 35%;
  height: 100%;
  border-radius: 1rem;
  background: #ffcb08;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: -0.5rem;
  margin-top: -1rem;
  border-radius: 1rem;
}

.info {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
}

.line {
  width: 3rem;
  height: 2px;
  background-color: #bebebe;
  margin-right: 10px;
}

.info .title .text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}

.slide {
  width: 100%;
  height: 100%;
  margin-top: 5%;
  padding-left: 2%;
}

.scontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  outline: 1px solid #fff;
}
</style>

<style>
.planswiper {
  width: 100%;
  height: 100%;
  padding: 0 15%;
}

.planswiper .swiper-slide {
  background: #90191d;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;

  outline: 1px solid #fff;
}

.planswiper .swiper-slide img {
  display: block;
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

.planswiper .swiper-slide p {
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.planswiper .swiper-slide hr {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 0.5rem 0;
}

.planswiper .swiper-button-prev,
.planswiper .swiper-button-next {
  color: #bebebe;
  /* border: 1px solid red; */
  /* padding: 2rem; */
}

.planswiper .swiper-button-prev {
  margin-left: -1%;
}
</style>
