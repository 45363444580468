<template>
  <div class="eventmain">
    <div class="title">
      <div class="line"></div>
      <div class="text">
        <div class="head">Our Latest Events</div>
        <div class="sub">
          Look at the way put our 100% to make your event special.
        </div>
      </div>
    </div>
    <swiper
      :spaceBetween="30"
      :centeredSlides="true"
      :autoplay="{ delay: 2500, disableOnInteraction: false }"
      :pagination="{ clickable: true }"
      :navigation="true"
      :modules="modules"
      class="myeventSwiper"
    >
      <swiper-slide v-for="sl in slidesdata" :key="sl.id"
        ><img :src="'http://13.233.174.75:5000/' + sl.image" alt="slide"
      /></swiper-slide>
      <!-- <swiper-slide
        ><img src="@/assets/mainslide.jpg" alt="slide"
      /></swiper-slide> -->
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slidesdata: [],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  methods: {
    inquire() {
      this.$router.push("#inquire");
    },

    async getSlides() {
      await this.axios
        .get("http://13.233.174.75:3000/api/gallery")
        .then((res) => {
          this.slidesdata = res.data;
        });
    },
  },
  mounted() {
    this.getSlides();
  },
};
</script>

<style scoped>
.eventmain {
  width: 100%;
  padding: 5% 5%;
  background: url("@/assets/eventbg.jpeg");
}

.eventmain .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.eventmain .title .line {
  margin-top: 1.5rem;
  margin-right: 1%;
  width: 6%;
  height: 3px;
  background: #bebebe;
}

.eventmain .title .text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.eventmain .title .text .head {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.eventmain .title .text .sub {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}

.eventmain .myeventSwiper {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.eventmain .myeventSwiper .swiper-slide img {
  width: 100%;
  height: 100%;
  padding: 0 7%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .eventmain .title .text .sub {
    width: 100%;
  }
}
</style>

<style>
.myeventSwiper .swiper-button-prev,
.myeventSwiper .swiper-button-next {
  color: #aeaeae;
  font-size: 1rem;
  border: 1px solid #aeaeae;
  height: 4rem;
  width: 4rem;
}

.myeventSwiper .swiper-button-prev::after,
.myeventSwiper .swiper-button-next::after {
  font-size: 2rem;
}
</style>
