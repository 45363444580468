<template>
  <div class="stats">
    <div class="stat">
      <img src="@/assets/statdhol.png" alt="EVENTS DONE" />
      <div class="title">
        <div class="main">{{ countara }}+</div>
        <div class="sub">EVENTS DONE</div>
      </div>
    </div>
    <div class="stat">
      <img src="@/assets/statshanai.png" alt="YEARS OF EXPERIENCE" />
      <div class="title">
        <div class="main">{{ counterb }}+</div>
        <div class="sub">YEARS OF EXPERIENCE</div>
      </div>
    </div>
    <div class="stat">
      <img src="@/assets/statppl.png" alt="EXPERIENCED STAFF" />
      <div class="title">
        <!-- <div class="main">50+</div> -->
        <!-- counter -->
        <div class="main">{{ count }}+</div>
        <div class="sub">EXPERIENCED STAFF</div>
      </div>
    </div>
    <div class="stat">
      <img src="@/assets/statbuilding.png" alt="AREA" />
      <div class="title">
        <div class="main">{{ countara }} Sq. Ft.</div>
        <div class="sub">OF AREA</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsComponent",
  data() {
    return {
      count: 0,
      countara: 0,
      counterb: 0,
    };
  },
  methods: {
    counter: function (num) {
      let count = 0;
      let interval = setInterval(() => {
        if (count < num) {
          count++;
        } else {
          clearInterval(interval);
        }
        this.count = count;
      }, 10);
    },

    counterara: function (num) {
      let countara = 0;
      let interval = setInterval(() => {
        if (countara < num) {
          countara = countara + 12;
        } else {
          clearInterval(interval);
        }
        this.countara = countara;
      }, 1);
    },

    counterbfn: function (num) {
      let counterb = 0;
      let interval = setInterval(() => {
        if (counterb < num) {
          counterb++;
        } else {
          clearInterval(interval);
        }
        this.counterb = counterb;
      }, 10);
    },
  },
  // after 2s
  mounted() {
    setTimeout(() => {
      this.counter(50);
      this.counterara(3000);
      this.counterbfn(70);
    }, 2000);
  },
};
</script>

<style scoped>
.stats {
  background: url(@/assets/planbg.jpg);
  padding: 5% 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stat img {
  height: 4rem;
}

.stat .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.stat .title .main {
  font-size: 3rem;
  font-weight: 700;
  color: #bb0b12;
}

.stat .title .sub {
  font-size: 1.5rem;
  font-weight: 600;
  color: #bb0b12;
}

.counter {
  color: #bb0b12;
  font-size: 3rem;
  font-weight: 700;
  animation: counter 2s ease-in-out;
}

@keyframes counter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
