<template>
  <footer>
    <div class="contact">
      <div class="ele">
        <div class="head">Call us for any help</div>
        <div class="sub">+91 83800 90021</div>
      </div>
      <div class="ele">
        <div class="head">Email us for support</div>
        <div class="sub">goremangal@gmail.com</div>
      </div>
      <div class="social">
        <div class="head">Follow us on</div>
        <div class="sub">
          <a href="https://www.facebook.com/goremangal" target="_blank"
            ><i class="bx bxl-facebook"></i
          ></a>
          <a href="https://www.instagram.com/goremangal/" target="_blank"
            ><i class="bx bxl-instagram"></i
          ></a>
          <!-- <a
            href="https://www.youtube.com/channel/UC9Z3Z4Z6Zy6Z6Z6Z6Z6Z6Z6"
            target="_blank"
            ><i class="bx fa-youtube"></i
          ></a> -->
        </div>
      </div>
    </div>
    <div class="copy">
      &copy; 2023 Gore Mangal Karyalaya. All Rights Reserved. <br />
      Developed By TF Strategies Pvt. Ltd.
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  background-color: #fff;
  width: 100%;
}

footer .contact {
  width: 100%;
  padding: 5% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 0; */
  border-bottom: 1px solid #bebebe;
}

footer .contact .ele {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

footer .contact .ele .head {
  font-size: 1.2rem;
  font-weight: 600;
  color: #bebebe;
}

footer .contact .ele .sub {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  color: #000;
}

footer .contact .social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

footer .contact .social .head {
  font-size: 1.5rem;
  font-weight: 600;
  color: #bebebe;
}

footer .contact .social .sub {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

footer .contact .social .sub a {
  text-decoration: none;
  margin-left: 1rem;
  font-size: 1.5rem;
  border: 1px solid #bebebe;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 50%;
  color: #bebebe;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

footer .contact .social .sub a:hover {
  color: #fff;
  background-color: #bebebe;
}

footer .copy {
  width: 100%;
  padding: 2% 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #bebebe;
  text-align: center;
}
</style>
