<template>
  <div class="formmain">
    <div class="qr">
      <img src="@/assets/qr.jpeg" alt="QR Code" />
      <h2>Booking Amount: <span>₹ 10000</span></h2>
    </div>
    <div class="close" @click="closeclk">
      <i class="bx bx-window-close"></i>
    </div>
    <div class="form">
      <p>Booking Date: {{ date }}</p>
      <select name="event" id="event" v-model="event">
        <!-- Select Function *
            Marriage
Reception
Munj
Barse
Dohale Jewan (Baby Shower)
Birthday's and Party -->
        <!-- <option value="">Select Function</option> -->
        <option value="Marriage">Marriage</option>
        <!-- <option value="Reception">Reception</option>
        <option value="Munj">Munj</option>
        <option value="Barse">Barse</option>
        <option value="Dohale Jewan (Baby Shower)">
          Dohale Jewan (Baby Shower)
        </option>
        <option value="Birthday's and Party">Birthday's and Party</option> -->
      </select>
      <input type="text" placeholder="Name" v-model="name" />
      <input type="text" placeholder="Email" v-model="email" />
      <input type="text" placeholder="Phone" v-model="mobile" />
      <input type="number" placeholder="Group Size" v-model="size" />
      <input type="text" placeholder="UTR Number" v-model="utr" />
      <!-- <input type="date" placeholder="Date" v-model="date" /> -->
      <button @click="submitinq">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverFormComponent",
  data() {
    return {
      event: "Marriage",
      name: "",
      email: "",
      mobile: "",
      size: "",
      date: "",
      utr: "",
    };
  },
  created() {
    this.date = localStorage.getItem("date");
  },
  methods: {
    closeclk() {
      this.$emit("closeclk");
      localStorage.removeItem("date");
    },

    formatdate(date) {
      let d = new Date(date);
      let month = d.getMonth() + 1;
      let day = d.getDate();
      let year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },

    async submitinq() {
      let data = {
        eventtype: this.event,
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        date: this.date,
        groupsize: this.size,
        utr: this.utr,
      };
      let res = await this.axios
        .post("http://13.233.174.75:3000/api/inquire", data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      if (res) {
        this.$emit("closeclk");
        localStorage.removeItem("date");
      }
    },
  },
};
</script>

<style scoped>
.formmain {
  /* floating */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* floating */
  /* width: 360px;
  height: 500px; */
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 5% 5%;
  margin: 0 5%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.formmain .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.formmain .close i {
  font-size: 2rem;
}

.formmain .qr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formmain .qr img {
  width: 100%;
}

.formmain .form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formmain .form input,
.formmain .form select {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.formmain .form input:focus,
.formmain .form select:focus {
  outline: none;
}

.formmain .form input::placeholder,
.formmain .form select::placeholder {
  color: #b3b3b3;
}

.formmain .form input[type="date"] {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.formmain .form input[type="date"]:focus {
  outline: none;
}

.formmain .form input[type="date"]::placeholder {
  color: #b3b3b3;
}

.formmain .form button {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  background-color: #fff;
  color: #b3b3b3;
  font-weight: bold;
  cursor: pointer;
}

.formmain .form button:hover {
  background-color: #b3b3b3;
  color: #fff;
}

.formmain .form select {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.formmain .form select:focus {
  outline: none;
}

.formmain .form select::placeholder {
  color: #b3b3b3;
}

@media (min-width: 768px) {
  .formmain {
    width: 50%;
  }
}
</style>
