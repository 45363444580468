<template>
  <div class="main">
    <over-form-component v-if="showform" @closeclk="closeclk" />
    <marriage-form-component v-if="marraigeform" @closeclk="closeclk" />
    <header-component />
    <router-view />
    <footer-component />
  </div>
</template>

<script>
import HeaderComponent from "./components/headerComponent.vue";
import FooterComponent from "./components/footerComponent.vue";
import overFormComponent from "./components/overFormComponent.vue";
import marriageFormComponent from "./components/marriageFormComponent.vue";
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    overFormComponent,

    marriageFormComponent,
  },
  data() {
    return {
      showform: false,
      marraigeform: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (to.hash) {
        // wait for page to render
        if (to.hash == "#inquire") {
          this.showform = true;
        }
        if (to.hash == "#marriage") {
          this.marraigeform = true;
        }

        setTimeout(() => {
          const element = document.getElementById(to.hash.slice(1));
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 1000);
      }
      // smooth scroll to top
    },
  },
  methods: {
    closeclk() {
      this.showform = false;
      // remove #inquire from url
      this.$router.replace({ hash: "" });
      this.marraigeform = false;
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Slab", serif;
}
</style>

<style lang="scss"></style>
